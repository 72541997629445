import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item has-dropdown menu-item-has-children">
                    <Link to="#">hAI <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li className='menu-item'><Link to={process.env.PUBLIC_URL + "/about-us" } className="nav-link">회사 소개</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/team"}>직원 소개</Link></li>
                    </ul>
                </li>
                <li className="menu-item">
                    <Link to={process.env.PUBLIC_URL + "/service-one"}>서비스</Link>
                </li>
           
                
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>문의하기</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;
import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "정직한 솔루션과 견적 제공",
        para: "현재 고객이 필요로 하는 사항과 현재상황을 종합적으로 고려하여 최적의 솔루션과 정확한 견적을 제공합니다. 만약 고객의 요구사항이 변경되면, 그에 맞는 각 단계별 설명과 실제 액션플랜까지 도출해드립니다. 저희 회사는 정직함을 가장 가치 있게 생각하며, 정확한 피드백과 컨설팅을 제공합니다."
    },
    {
        id: 2,
        title: "프로젝트 오너십 및 책임감",
        para: "일이 수주되어 시작되면, 모든 프로젝트는 담당자가 정해지며 그 담당자가 고객과의 연락 및 프로젝트 관리를 담당합니다. 저희 개발자들은 각각의 프로젝트에 TF팀처럼 참여하지만, 각 프로젝트의 오너는 코드의 퀄리티를 최우선으로 생각하고 관리합니다. 이 프로젝트는 외주 작업이 아니라 담당자의 자존심과 능력이 달린 중요한 작업으로 여겨집니다."
    },
    {
        id: 3,
        title: "지속가능성 및 안정성",
        para: "저희는 캐나다 대기업 및 스타트업에서 스케일 업을 경험한 전문 개발자들로 구성되어 있습니다. 코드의 안정성은 물론 스케일업이 되었을 경우 클라우드 서비스를 활용한 스케일업 구조를 갖춘 프로그래밍을 하기에 지속 가능하고 안정적인 코드를 결과물로 받을 수 있습니다."
    },
    {
        id: 4,
        title: "개발팀 코딩 실력",
        para: "저희 회사의 코드는 최신 트렌드에 맞게 작성되어 최신 기술 스택을 사용하는 어떤 개발 회사나 팀에서도 믿고 외주를 맡길 수 있습니다. 이미 작성된 코드 파악부터 바로 작업에 들어가 빠르게 도와드릴 수 있습니다. 실례로, 주말에 기능 추가를 2시간 내에 해결한 경험이나 기존 코드를 다국어 지원 가능하도록 리팩토링한 경험이 있습니다. 아래 리뷰에서 확인해주세요."
    },
    {
        id: 5,
        title: "자체 개발",
        para: "저희는 절대로 하청업체에 일을 맡기지 않습니다. 저희 개발자들로만 구성된 팀이 최고의 코드를 제공합니다. 저희의 목표는 퀄리티를 최우선으로 하는 것입니다. 이는 개발자의 욕심에 의한 퀄리티가 아닌, 기능이 기획에 맞게 정확히 동작하는지를 중요하게 생각합니다."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
            <SectionTitle 
                subtitle="우리가 추구하는 가치"
                title="왜 우리와 함께 일해야 <br/>하는가?"
                description="현재 한국 개발자 시장은 과잉 공급 상태입니다. 하지만 저희는 개발자를 뽑을 때 가장 효율적이고 논리적인 인재만을 선발하기 위해 많은 노력을 기울입니다. 특히, 저희 CEO는 컴퓨터 공학으로 세계적으로 유명한 토론토대학교 출신으로, 이러한 전문성을 바탕으로 높은 기준을 유지하고 있습니다. 이렇게 선발된 인재들과 함께 유지보수가 용이하고 장기적으로 유리한 프로그램을 개발합니다."
                textAlignment="heading-left heading-light-left mb--100"
                textColor=""
            />


                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;
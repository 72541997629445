import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-us-3.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                        <h2 className="title">고객 맞춤형 <br/> 소프트웨어 개발</h2>
                            <p>저희 개발팀은 대기업부터 초기 스타트업까지 다양한 규모의 회사에서 경험을 쌓아온 전문가들로 구성되어 있습니다. 
                            특히 저희 CEO는 10명 규모의 초기 의료 AI 스타트업에서 시작해, 100명 내외의 회사로 성장시키는 과정에서 개발 그룹장으로서의 역할을 완벽히 수행한 경험이 있습니다.</p>
                            <p>각각의 단계에서 필요한 지원과 해결책을 제공할 수 있는 저희 팀은 퀄리티와 속도의 균형을 완벽하게 맞추어 드립니다. 모든 프로젝트에서 최상의 결과를 보장합니다.</p>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count" style={{color:'green'}}>
                                        {isVisible ? <CountUp end="20" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol" style={{color:'green'}}>+ </span>
                            </h2>
                            <span className="counter-title" >성공한 프로젝트</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count" style={{color:"#FFCD00"}}>
                                        {isVisible ? <CountUp end="4.5" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol" style={{color:"#FFCD00"}}> &nbsp; / 5</span>
                            </h2>
                            <span className="counter-title">
                                평균 별점
                                
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;
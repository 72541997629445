import React from 'react';
import { Link } from 'react-router-dom';
import TeamData from "../../data/team/TeamData.json";
import { slugify } from '../../utils';

const allData = TeamData;

const TeamOne = () => {
    return (
        <div className="section section-padding pb--70 pb_lg--20 pb_md--0">
        <div className="container">
            <div className="section-heading">
            <span className="subtitle">우리의 가치</span>
            <h2 className="title mb--50">팀을 소개합니다</h2>
            <p>최고의 팀과 함께 고객에게 최적의 솔루션을 제공합니다. 우리의 전문성과 헌신을 경험해보세요.</p>
            </div>
            <div className="row">
                {allData.map((data) => (
                    <div className="col-xl-3 col-sm-6" key={data.id}>
                        <div className="team-grid">
                            <div className="thumbnail">
                                    <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} />
                            </div>
                            <div className="content">
                                <h4 className="title">
                                        {data.title}
                                </h4>
                                <span className="designation" dangerouslySetInnerHTML={{__html: data.designation}}></span>
                                <p className="description"> {data.description}</p>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
        </ul>
    </div>

    )
}

export default TeamOne;
import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            성공적으로 전송되었습니다.
        </Alert>
    )
}

const FormOne = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);
    const [ name, setName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ content, setContent ] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        
        const fd = { "content":content,
        
            "from_name": name, "from_email": email, "from_phone": phone}
        emailjs.send("service_uqzza6w", "template_w6v745c",fd, 'HGPoLKLiQPOstBSlL')
        .then((result) => {
            console.log(result.text);
            showresult(true);
        }, (error) => {
            console.log(error.text);
        });
    
      
      };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
        <div className="form-group">
            <label>이름</label>
            <input type="text" className="form-control" name="from-name" 
            onChange={(e) => setName(e.target.value)}
            value={name} placeholder="홍길동" required />
        
        </div>
        <div className="form-group">
            <label>이메일</label>
            <input type="email" className="form-control" name="from-email"
            onChange={(e) => setEmail(e.target.value)}
            value={email} placeholder=""
            required />  
        </div>
        <div className="form-group mb--40">
            <label>전화번호</label>
            <input type="text" className="form-control" name="from-phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone} placeholder="010-1234-5678" required />

        </div>
        <div className="form-group mb--40">
            <label>
                어떻게 도와드릴까요?
            </label>
            <textarea value={content}
            onChange = {(e) => setContent(e.target.value)}
            className="form-control" name="contact-message" rows="4"></textarea>
            
        </div>
        <div className="form-group">
            <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">
                견적 요청하기
            </button>
        </div>
        
        <div className="form-group">
            {result ? <Result /> : null}
        </div>
        

    </form>
    )
}

export default FormOne;
import React from 'react';
import { Link } from 'react-router-dom';


const FooterTwo = () => {

    return (
        <footer className="footer-area footer-dark">
            <div className="container">
                <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-6">
                    <div className="footer-copyright">
                        <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by <a href="https://www.haicorp.kr/">주식회사 에이치에이아이（hAi Corp.）
                        </a>.</span>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="footer-bottom-link">
                        <ul className="list-unstyled">
                            <li><b>사업자 등록번호</b> 809-86-01792</li>
                            <li><b>대표이사</b> 송치현</li>
                            <li><b>주소</b> 서울특별시 마포구 토정로35길 11 (용강동, 인우빌딩) 511호</li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterTwo;
import React from 'react';
import FormOne from '../contact/FormOne';

const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">회사 소개</span>
                                <h2 className="title mb--40">우리는 기획하고, 개발하며, 완벽히 운영합니다.</h2>
                                <p>우리는 최고의 품질과 서비스를 제공하기 위해 노력합니다. 항상 고객의 요구를 최우선으로 생각하며, 프로젝트의 모든 단계에서 세심한 주의를 기울입니다.</p>
                                <p>우리는 Node.js, React.js, Flutter 등의 최신 기술 스택과 관계형 데이터베이스 및 MongoDB를 활용하여 고객에게 딱 맞는 기술 스택 추천부터 기술 개발까지 한 번에 제공합니다.</p>
                                <p>고객의 성공이 우리의 성공이라고 믿습니다. 언제나 높은 수준의 프로페셔널리즘과 열정으로 최상의 결과를 제공합니다.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">지금 무료로 견적을 <br/>받아보세요</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="버블" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="라인" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="라인" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;
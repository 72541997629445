import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import TestimonialOne from '../component/testimonial/TestimonialOne';
import { slugify } from '../utils';
import FooterTwo from '../common/footer/FooterTwo';
const allData = ServiceData;
const services = [
    {
        id: 1,
        title: "모바일 / 웹 개발",
        subtitle: "PC / Mobile Web 사이트 개발",
        description: "최적의 기획과 설계를 통해 국내외의 폭넓은 범위의 홈페이지 제작 경험으로 고객의 생각을 풀이 해냅니다.",
    },
    {
        id: 2,
        title: "서비스 기능 추가 및 변경",
        subtitle: "기능 추가 / 변경",
        description: "Node.js, React.js 등 최신 스택으로 개발된 프로젝트의 수정사항이나 버그 수정도 성심성의껏 도와드립니다."
    },
    {
        id: 3,
        title: "앱 컨설팅",
        subtitle: "앱 컨설팅 서비스",
        description: "사용자 요구 사항을 충족하고 기능적이면서 사용자 친화적인 소프트웨어를 제공할 수 있도록 지원합니다.",
    },
    {
        id: 4,
        title: "AI 컨설팅",
        subtitle: "AI 컨설팅 서비스",
        description: "최신 AI 기술을 도입하여 서비스의 기능과 성능을 향상시키고 비즈니스 목표를 달성할 수 있도록 지원합니다.",
    },
];


const ServiceOne = () => {

    const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");
    const developmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "development");
    const marketingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "online-marketing");
    const businessData = allData.filter(data => slugify(data.cate ? data.cate : "") === "business");
    const technologyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "technology");
    const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "content-strategy");



    return (
        <>
        <SEO title="Service One" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
    title="최적의 디지털 컨설팅 솔루션"
    paragraph="당신의 비즈니스 아이디어를 성공으로 이끄는 최첨단 개발 솔루션을 제공합니다."
    styleClass=""
    mainThumb="/images/banner/banner-thumb-4.png"
/>
                <div className="section section-padding">
                    <div className="container">
                        <h2 className="service-title">hAi 제공 서비스</h2>
                        <div className="service-list">
                            {services.map(service => (
                                <div key={service.id} className="service-item">
                                    <div className="service-header">
                                        <h3>{service.title}</h3>
                                        <p>{service.subtitle}</p>
                                    </div>
                                    <div className="service-body">
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
        </div>
     
            <TestimonialOne />
            <CtaLayoutOne />
            <FooterTwo />
        </main>
        </>
    )
}

export default ServiceOne;
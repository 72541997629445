import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
        <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="about-team">
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/about/ceo-2.jpg"} width="500px" alt="thumbnail" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="about-team">
                            <div className="section-heading heading-left">
                                <span className="subtitle">CEO 한마디</span>
                                <h2>송치현</h2>
                                <h3>학력</h3>
                                <p>University of Toronto (BSc Computer Science)</p>
                                <h3>CEO 한마디</h3>
                                <p>
                                    웹 개발자로 일하고 그룹장까지 하면서 몇 번의 외주 경험을 통해 많은 것을 배웠습니다.<br />
                                    왜 내 일처럼 신경 써주는 외주회사는 찾기 힘들고, 왜 코드 퀄리티가 보장되지 않는가?<br />
                                    이 문제를 해결하기 위해 저의 경험을 바탕으로 정직하게 접근하고자 합니다. 다른 기업들이 당면한 어려움을 높은 퀄리티의 코드로 해결하며, 앞으로 지속 가능하고 더 큰 비즈니스를 함께 도모할 수 있는 결과물을 제공하고자 합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutFive;
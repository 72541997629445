import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';

import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import AboutOne from '../component/about/AboutOne';
const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <AboutFour />
                <AboutThree />
                <AboutFive />
                <ProcessOne />
                <AboutOne />
                <FooterTwo />

            </main>
        </>
    )
}

export default AboutUs;
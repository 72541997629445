import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import FormOne from '../component/contact/FormOne';


const Contact = () => {
    return (
        <>
            <SEO title="문의하기" />
       
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="문의하기"
                page="문의하기"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">무료 견적 받기</h3>
                                <FormOne />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                      
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>지원 팀은 표준 업무 시간 동안 24시간 내에 회신해 드립니다.</p>
                                <h4 className="phone-number"><a href="mailto:help@haicorp.kr">help@haicorp.kr</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>

            <FooterTwo />

            </main>
        </>
    )
}

export default Contact;